import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

const ArtistLink = ({ node }) => {
    return (
        <div>
            <Link to={node.fields.slug}>
                {node.frontmatter.title} - {node.frontmatter.key}
            </Link>
        </div>
    )
}

export default function ArtistList(props) {
    return (
        <StaticQuery
            query={graphql`
          query {
            allMarkdownRemark {
              nodes {
                id
                frontmatter {
                    title
                    key
                }
                fields {
                  slug
                }
              }
            }
          }
        `}
            render={data => (
                <div>
                    {data && data.allMarkdownRemark.nodes.map(node => (
                        <ArtistLink key={node.id} node={node} />
                    ))}
                </div>
            )
            }
        />
    )
}
